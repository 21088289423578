const claimInformations = [
  {
    type_claims: 'Retur',
    list_condition: [
      'Memberikan bukti dengan jelas chat antara Seller dan Customer :',
      'Memberikan bukti yang menjelaskan seperti apa Customer dihubingi Kurir (Telp/Chat).',
    ],
    sublist_condition_1: ['Customer masih berminat membayar COD'],
    sublist_condition_2: null,
    note_claims: null,
    tooltip: null,
  },
  {
    type_claims: 'Force RTS',
    list_condition: [
      'Melampirkan bukti dengan jelas :',
      'Melampirkan bukti dengan jelas :',
    ],
    sublist_condition_1: [
      'Foto/Video Paket diterima oleh Seller',
      'Foto/Video tersebut jelas dengan Label dan Resi Komship dan Ekspedisi',
      'Keterangan waktu jelas',
    ],
    sublist_condition_2: ['Foto/Video unboxing paket dengan label dan resi'],
    note_claims: 'Orderan ini akan berubah statusnya menjadi “Retur Diterima” setelah diperiksa dan disetujui oleh Admin',
    tooltip: 'Lengkapi keterangan rekomendasi untuk mempermudah tim mengidentifikasi dan potensi disetujui lebih besar',
  },
  {
    type_claims: 'Force Delivered',
    list_condition: [
      'Melampirkan bukti dengan jelas :',
      'Melampirkan bukti dengan jelas :',
    ],
    sublist_condition_1: [
      'Foto/Video Paket diterima oleh Customer',
      'Foto/Video Semua Sisi Paket sebelum dibuka (untuk melihat kondisi paket setelah penerimaan)',
      'Keterangan waktu jelas',
    ],
    sublist_condition_2: ['Foto/Video unboxing paket dengan label dan resi'],
    note_claims: 'Orderan ini akan berubah statusnya menjadi “Diterima” setelah diperiksa dan disetujui oleh Admin',
    tooltip: 'Lengkapi keterangan rekomendasi untuk mempermudah tim mengidentifikasi dan potensi disetujui lebih besar',
  },
]

export default claimInformations

<template>
  <BCard>
    <div class="flex flex-row justify-between items-center mt-2 mb-3">
      <div class="text-black text-2xl font-semibold">
        {{ title }}
      </div>
    </div>
    <BRow>
      <BCol
        cols="7"
        class="border p-2"
      >
        <div
          v-if="typeClaims !== 'Retur'"
          class="form-group mb-2"
        >
          <div
            for="video"
            class="mb-2 mr-0 text-black"
          >Upload Video</div>
          <div class="input-group">
            <input
              id="video"
              v-model="url"
              type="text"
              class="form-control input-proof"
              placeholder="Masukkan link video"
              @input="validationUrl"
            >
            <div class="input-group-append">
              <span
                class="input-group-text input-proof"
              >Link</span>
            </div>
          </div>
          <small
            v-if="urlError === 'invalid'"
            class="text-primary text-xs"
          >*URL tidak valid</small>
          <small
            class="form-text text-xs"
          >Link yang dikirimkan berisikan video dan pastikan dapat dibaca oleh publik</small>
        </div>
        <div class="mb-2 mr-0 text-black">
          Upload Foto
          <span class="text-danger m-0">*</span>
        </div>
        <div
          class="bg-[#FFFFFF] border-2 border-[#E2E2E2] rounded-lg p-1 h-[500px]"
        >
          <div
            id="drop-zone"
            class="border-2 bg-[#F8F8F8] border-dashed border-[#E2E2E2] rounded-xl h-75 text-center place-content-center"
            @dragover.prevent="handleDragOver"
            @drop.prevent="handleDrop"
          >
            <label for="image-product">
              <div class="d-flex justify-content-center">
                <img
                  class="w-20"
                  src="https://storage.googleapis.com/komerce/assets/icons/upload-image.svg"
                  alt="img"
                >
              </div>
              <div class="font-semibold text-xl mt-2">
                Drop foto disini, atau
                <span class="text-primary cursor-pointer">pilih</span>
              </div>
              <div class="text-lg text-[#C2C2C2] mt-1">
                file berupa JPG, JPEG, PNG
                <span class="text-primary">Max 2 Mb</span>
              </div>
            </label>
            <div
              v-if="imageError !== ''"
              class="justify-center d-flex"
            >
              <div class="bg-[#FFFFFF] rounded-xl p-[10px] text-start d-flex mt-4">
                <div>
                  <span class="k-close bg-[#E31A1A] text-[#FFFFFF] text-xl rounded-pill mr-[10px]" />
                </div>
                <span class="text-black">
                  {{ imageError }}
                </span>
              </div>
            </div>
          </div>
          <b-form-file
            id="image-product"
            class="d-none"
            multiple
            required
            accept="image/x-png, image/jpg, image/jpeg"
            @input="handleInput"
          />
          <div
            v-if="fileImage.length !== 0"
            class="justify-center d-flex my-2"
          >
            <div
              v-for="(item, index) in fileImage"
              :key="index"
              class="d-flex"
            >
              <img
                class="w-20 h-20 rounded-xl"
                :src="item.url"
                alt=""
              >
              <div class="">
                <span
                  class="k-close bg-[#E31A1A] text-[#FFFFFF] text-lg rounded-pill mr-[10px] relative -top-3 right-3 cursor-pointer"
                  @click="removeFile(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </BCol>
      <BCol class="border p-2">
        <div
          v-for="(item, index) in listInfoClaims"
          :key="index"
        >
          <div v-if="item.type_claims === typeClaims">
            <div class="bg-[#DFF3FF] border-2 border-[#08A0F7] rounded-lg p-1 text-black">
              <div class="text-lg font-semibold">
                Ketentuan Claim {{ item.type_claims }}
              </div>
              <div
                v-for="(list, idx) in item.list_condition"
                :key="idx"
                class="my-1"
              >
                {{ idx + 1 }}. {{ list }}
                <div
                  v-if="idx === 0 && item.sublist_condition_1 !== null"
                  class="ml-1"
                  style="list-style-type: lower-alpha;"
                >
                  <li
                    v-for="sublist in item.sublist_condition_1"
                    :key="sublist"
                  >
                    {{ sublist }}
                  </li>
                </div>
                <div v-if="idx === 1 && item.sublist_condition_2 !== null">
                  <span class="text-[#828282] mr-[10px]">(Rekomendasi)</span>
                  <span
                    id="tooltip-info"
                    class="k-info-circle text-[#08A0F7] text-xl align-center"
                  />
                  <b-tooltip
                    variant="light"
                    target="tooltip-info"
                    triggers="hover"
                  >
                    <div class="text-left">
                      {{ item.tooltip }}
                    </div>
                  </b-tooltip>
                </div>
                <div
                  v-if="idx === 1 && item.sublist_condition_2 !== null"
                  class="ml-1"
                  style="list-style-type: lower-alpha;"
                >
                  <li
                    v-for="sublist in item.sublist_condition_2"
                    :key="sublist"
                  >
                    {{ sublist }}
                  </li>
                </div>
              </div>
            </div>
            <div
              v-if="typeClaims !== 'Retur'"
              class="bg-[#FFF2E2] text-[#AF6A13] p-1 my-2 rounded-lg"
            >
              {{ item.note_claims }}
            </div>
          </div>
        </div>
      </BCol>
    </BRow>
    <div class="d-flex justify-content-end gap-10 my-2">
      <BButton
        style="width: 15%;"
        variant="outline-primary"
        @click="$bvModal.show('modal-confirm-back')"
      >
        Batal
      </BButton>
      <BButton
        style="width: 15%;"
        :variant="isDisabled ? 'secondary' : 'primary'"
        :disabled="isDisabled"
        @click="$bvModal.show('modal-confirm-submit')"
      >
        Laporkan
      </BButton>
    </div>

    <ModalConfirm
      id="modal-confirm-submit"
      title="Konfirmasi Pengajuan Klaim"
      label-confirm-button="Ajukan"
      label-cancel-button="Batal"
      @on-click-confirm-button="submit()"
      @on-click-cancel-button="$bvModal.hide('modal-confirm-submit')"
    >
      <template #message>
        <p class="mb-0 text-[#333333]">
          Formulir hanya dapat diisi sekali. Pastikan informasi sudah benar. Klik <b>'Ajukan'</b> untuk melanjutkan?
        </p>
      </template>
    </ModalConfirm>
    <ModalConfirm
      id="modal-confirm-back"
      title="Batalkan Pengisian Klaim ?"
      message="Apakah kamu yakin ingin membatalkan pengisian formulir? Semua data yang telah diisi akan hilang"
      label-confirm-button="Tidak"
      label-cancel-button="Iya"
      @on-click-confirm-button="$bvModal.hide('modal-confirm-back')"
      @on-click-cancel-button="$router.go(-1)"
    />
  </BCard>
</template>

<script>
import ModalConfirm from '@/views/components/modal/ModalConfirm.vue'
import claimInformations from './config'

const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/

export default {
  components: { ModalConfirm },
  data() {
    return {
      title: this.$route.params.title,
      typeClaims: this.$route.params.typeClaims,
      ticketId: this.$route.params.ticketId,
      orderId: this.$route.params.orderId,
      typeParams: this.$route.params.typeParams,

      listInfoClaims: claimInformations,

      url: null,
      urlError: '',

      fileImage: [],
      imageError: '',
    }
  },
  computed: {
    isDisabled() {
      let result
      if (this.fileImage.length === 0) {
        result = true
      }
      return result
    },
  },
  mounted() {
    if (this.$route.params.orderId === undefined) {
      this.$toast_error({ message: 'You Not Authorized This Page !' })
      this.$router.go(-1)
    }
  },
  methods: {
    validationUrl() {
      if (this.url !== '') {
        if (!urlPattern.test(this.url)) {
          this.urlError = 'invalid'
        } else {
          this.urlError = ''
        }
      } else {
        this.urlError = ''
      }
    },
    isAcceptedFile(fileName) {
      const acceptedExtensions = ['jpg', 'jpeg', 'png']
      const extension = fileName.split('.').pop().toLowerCase()
      return acceptedExtensions.includes(extension)
    },
    handleDragOver(e) {
      e.preventDefault()
      e.stopPropagation()

      this.imageError = ''
    },
    handleDrop(e) {
      e.preventDefault()
      e.stopPropagation()

      this.imageError = ''
      const fileImage = Array.from(e.dataTransfer.files)

      this.validateImage(fileImage)
    },
    handleInput(e) {
      this.imageError = ''
      const fileImage = e

      this.validateImage(fileImage)
    },
    async validateImage(file) {
      if (this.fileImage.concat(file).length <= 3) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < file.length; i++) {
          const item = file[i]

          if (this.isAcceptedFile(item.name)) {
            if (item.size <= 2000000) {
              // eslint-disable-next-line no-await-in-loop
              await this.uploadImg(item)
            } else {
              this.imageError = 'Ukuran file melebihi batas 2MB'
            }
          } else {
            this.imageError = 'Format file tidak didukung, hanya JPG, JPEG, PNG'
          }
        }
      } else {
        this.imageError = 'Maksimal upload 3 gambar'
      }
    },
    async uploadImg(file) {
      const formData = new FormData()
      formData.append('file', file)
      await this.$http_new
        .post('komship/api/v1/claims/upload-img/temp', formData)
        .then(response => {
          const { data } = response.data
          this.fileImage.push(data)
        })
        .catch(err => {
          this.$toast_error({
            message: 'Gagal upload image',
          })
        })
    },
    removeFile(index) {
      this.fileImage.splice(index, 1)
    },
    submit() {
      this.isDisabled = true
      const params = {
        order_id: Number(this.orderId),
        type: this.typeParams,
        images_id: this.fileImage.map(file => file.id),
      }

      if (this.url !== null) Object.assign(params, { video_url: this.url })
      if (this.typeParams === 'retur') Object.assign(params, { ticket_id: Number(this.ticketId) })

      this.$http_new
        .post('komship/api/v1/claims', params)
        .then(() => {
          this.isDisabled = false
          this.$toast_success({ message: `${this.typeClaims === 'Retur' ? 'Klaim retur' : this.typeClaims} kamu telah sukses diajukan. Tim kami akan memverifikasi dalam 1x24 jam. Kamu bisa memantau status ${this.typeClaims === 'Retur' ? 'klaim di detail tiket' : 'pengajuan di detail order'}` })
          this.$router.go(-1)
        }).catch(err => {
          this.isDisabled = false
          this.$toast_error({ message: `Maaf, ${this.typeClaims === 'Retur' ? 'Klaim retur' : this.typeClaims} kamu tidak dapat diproses. Silakan periksa kembali nanti atau hubungi layanan pelanggan untuk bantuan lebih lanjut.` })
        })
    },
  },
}
</script>
